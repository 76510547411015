import Converter, {
  availableTargetExtensions,
} from '@/utils/templatesConverter/Converter'
import { serialize } from 'object-to-formdata'
import { DEFAULT_REQUEST_DOCUMENT_SETTINGS } from '@/store/template.module'
import { mapActions } from 'vuex'
const converter = new Converter()

export default {
  data() {
    return {
      uploadDropFiles: [],
      errorMessage: null,
      maxFileSize: 100 * 1024 * 1024, // 100 MB
      allowedFormats: ['DOCX', 'XLSX', 'XLS', 'PDF'],
    }
  },
  methods: {
    ...mapActions({
      addShortcut: 'addShortcut',
    }),
    onFileUpload(files, packageId, priority) {
      this.errorMessage = null
      this.uploadDropFiles = Array.from(files)

      this.uploadDropFiles.forEach((file) => {
        if (
          !this.allowedFormats.includes(
            file.name.split('.').pop()?.toUpperCase(),
          )
        ) {
          this.errorMessage = `Invalid file format: ${file.name}`
          return
        }
        if (file.size > this.maxFileSize) {
          this.errorMessage = `File size too large: ${file.name}`
          return
        }
      })

      if (this.errorMessage) {
        this.toast('error', this.errorMessage)
      }

      if (!this.errorMessage && this.uploadDropFiles.length) {
        const priorityDictionary = {
          1: 'primary',
          2: 'secondary',
        }

        this.uploadDropFilesHandler(packageId, priorityDictionary[priority])
      }
    },
    uploadDropFilesHandler(packageId, priority) {
      this.uploadDropFiles.forEach((file) => {
        //File convertable
        const extension = file.name.split('.').pop()
        if (Object.values(availableTargetExtensions).includes(extension)) {
          converter
            .convertFile(file)
            .then((res) => {
              let name = file.name.split('.')
              name.pop()
              let data = {
                type: 'Contracts',
                name: name.join('.'),
                content: res.html,
                is_published: false,
              }
              if (packageId) {
                data.folder_id = packageId
                data.priority = priority
              }
              this.$http.library
                .createTemplate(data)
                .then((response) => {
                  this.toast('success', response.data.message)
                  this.updateData()
                })
                .catch((error) => {
                  const message =
                    error.response.data?.errors?.name[0] ||
                    error.response.data?.message ||
                    'Error'
                  this.toast('error', message)
                })
            })
            .catch(() => {
              this.toast('error', 'The file is damaged or in the wrong format')
            })
        } else {
          //File not convertable, create manual signature task settings
          let fileName = file.name.split('.')
          fileName.pop()
          fileName = fileName.join('.')
          const formState = serialize(
            {
              ...DEFAULT_REQUEST_DOCUMENT_SETTINGS,
              ...{
                file,
                manual_signature: 1,
                name: fileName ?? 'Manual Signature Task',
                task_name: fileName ?? 'Manual Signature Task',
              },
            },
            { booleansAsIntegers: true },
          )

          this.$http.tasks
            .createTaskSetting(formState)
            .then((response) => {
              this.toast('success', response.data.message)

              if (packageId) {
                const data = {
                  template_id: [response.data.template_id],
                  add_to_folder_id: packageId,
                  type: priority,
                }
                this.addShortcut(data).then(() => {
                  this.updateData()
                })
              }
            })
            .catch((error) => {
              this.toast('error', error.response.data.message)
            })
        }
      })
    },
  },
}
