<template>
  <CModal
    content-class-name="add-templates-popup"
    size="lg"
    backdrop="static"
    :visible="visible"
    @close="closePopup"
  >
    <CModalHeader class="add-templates-popup__header">
      <CModalTitle class="add-templates-popup__heading">
        {{ data.title }}
      </CModalTitle>
    </CModalHeader>

    <CModalBody v-if="fromLibrary" class="add-templates-popup__body">
      <div v-if="loading" class="add-templates-popup__loader">
        <CSpinner color="primary" />
      </div>

      <ul v-else class="add-templates-popup__list">
        <li
          v-for="template in templates"
          :key="template.id"
          class="add-templates-popup__list-item"
        >
          <label class="add-templates-popup__label">
            <input
              v-model="selectedTemplates"
              class="add-templates-popup__checkbox"
              type="checkbox"
              :value="template.id"
            />

            <CIcon
              class="add-templates-popup__icon"
              size="sm"
              :name="setIconNameByTemplateType(template.type)"
            />

            <span class="add-templates-popup__text">{{ template.name }}</span>
          </label>
        </li>
      </ul>
    </CModalBody>

    <CModalFooter class="add-templates-popup__footer">
      <CButton
        class="add-templates-popup__button"
        variant="outline"
        color="primary"
        :disabled="disabledButtons || loading"
        @click="addFromDeviceHandler"
      >
        Create Template
      </CButton>

      <CButton
        class="add-templates-popup__button"
        :variant="!fromLibrary && 'outline'"
        color="primary"
        :disabled="
          disabledButtons ||
          loading ||
          (fromLibrary && !selectedTemplates.length)
        "
        @click="addFromLibraryHandler"
      >
        {{ addFromLibraryButtonText }}
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import { templatesListAdapter } from '@/tools/DataAdapters/library'
import { generateLinkIcon } from '@/views/Templates/Components/Table/generatorLinkByTemplateType'
import { mapActions } from 'vuex'

export default {
  name: 'AddTemplatesPopup',
  inject: ['toast'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'updateData', 'addFromDevice'],
  data() {
    return {
      fromLibrary: false,
      loading: false,
      disabledButtons: false,

      templates: [],
      selectedTemplates: [],
    }
  },
  computed: {
    addFromLibraryButtonText() {
      return this.fromLibrary ? 'Add shortcut' : 'Add from library'
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.fromLibrary = false
        this.loading = false
        this.disabledButtons = false
        this.templates = []
        this.selectedTemplates = []
      }
    },
    fromLibrary(value) {
      if (!value) {
        this.templates = []
      } else {
        this.fetchLibraryTemplates()
      }
    },
  },
  methods: {
    ...mapActions({
      addShortcut: 'addShortcut',
    }),

    async fetchLibraryTemplates() {
      this.loading = true
      await this.$http.library
        .getAllTemplates({
          params: {
            sort_field: 'name',
            sort_direction: 'asc',
            per_page: -1,
            page: 1,
          },
        })
        .then((response) => {
          const templates = templatesListAdapter(response.data.data)
          this.templates = templates
        })
        .catch((error) => {
          console.log(error.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    closePopup() {
      this.$emit('close')
    },
    addFromDeviceHandler() {
      this.$emit('createTemplate', {
        packageId: this.data.packageId,
        priority: this.data.type,
      })
      this.closePopup()
    },
    addFromLibraryHandler() {
      if (this.fromLibrary) {
        const data = {
          template_id: this.selectedTemplates,
          add_to_folder_id: this.data.packageId,
          type: this.data.type,
        }
        this.disabledButtons = true
        this.addShortcut(data)
          .then((response) => {
            this.closePopup()
            this.toast('success', response)
            this.$emit('updateData')
          })
          .finally(() => (this.disabledButtons = false))
      } else {
        this.fromLibrary = true
      }
    },
    setIconNameByTemplateType(type) {
      return generateLinkIcon(type)
    },
  },
}
</script>

<style lang="scss">
.add-templates-popup {
  max-width: 560px;
  padding: 8px;

  &__header {
    position: relative;
    justify-content: center;

    & .btn.btn-close {
      position: absolute;
      top: 15px;
      right: 5px;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }

  &__icon {
    margin-right: 8px;
  }

  &__text {
  }

  &__list {
    max-height: 50vh;
    overflow-y: scroll;
    list-style: none;
    padding: 0;
  }

  &__loader {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 25px;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }
  }

  &__checkbox {
    margin-right: 15px;
  }

  &__footer {
    justify-content: center;
  }

  &__button {
    min-width: 140px;
  }
}
</style>
