<template>
  <div class="view-details">
    <div class="view-details__header">
      <h3 class="view-details__heading">View Details</h3>

      <CButton
        class="view-details__cross"
        size="sm"
        color="link"
        @click="close"
      >
        <CIcon
          size="sm"
          name="cilX"
          class="view-details__icon view-details__icon--x"
        />
      </CButton>
    </div>

    <div v-if="!loading" class="view-details__body">
      <div class="view-details__template">
        <CIcon
          :key="setIconNameByTemplateType"
          :name="setIconNameByTemplateType"
          class="view-details__icon view-details__icon--description"
        />

        <router-link
          :to="generateLinkByType"
          class="view-details__template-link"
        >
          {{ templateName }}
        </router-link>
      </div>

      <div class="view-details__subheading">Location:</div>

      <div class="view-details__content">
        <ul v-if="usageList.length" class="view-details__list">
          <li
            v-for="scenario in usageList"
            :key="scenario.id"
            class="view-details__list-item"
          >
            <CButton
              size="sm"
              color="link"
              class="view-details__list-button"
              @click="setActiveScenario(scenario.id)"
            >
              <CIcon
                name="cilLayers"
                size="sm"
                class="view-details__icon view-details__icon--layers"
              />

              <Popper :content="scenario.name">
                <span class="view-details__list-text">{{ scenario.name }}</span>
              </Popper>
            </CButton>

            <ul v-if="scenario.packages?.length" class="view-details__sublist">
              <li
                v-for="packageData in scenario.packages"
                :key="packageData.id"
                class="view-details__sublist-item"
              >
                <CButton
                  size="sm"
                  color="link"
                  class="view-details__list-button"
                  @click="setActiveScenario(scenario.id, packageData.id)"
                >
                  <CIcon
                    name="cilFolder"
                    size="sm"
                    class="view-details__icon view-details__icon--folder"
                  />

                  <Popper :content="packageData.name">
                    <span class="view-details__list-text">
                      {{ packageData.name }}
                    </span>
                  </Popper>
                </CButton>
              </li>
            </ul>
          </li>
        </ul>

        <p v-else class="view-details__empty">
          This template has not been added to any scenario yet
        </p>
      </div>

      <div class="view-details__info">
        <div class="view-details__info-item">
          <span class="view-details__info-text">Size</span>
          <span class="view-details__info-text">{{ size }}</span>
        </div>
        <div class="view-details__info-item">
          <span class="view-details__info-text"> Created By </span>
          <span class="view-details__info-text"> {{ createdBy }} </span>
        </div>
        <div class="view-details__info-item">
          <span class="view-details__info-text"> Last modified </span>
          <span class="view-details__info-text">
            {{ $DwT(lastModified) || '' }}
          </span>
        </div>
        <div class="view-details__info-item">
          <span class="view-details__info-text"> Creation date </span>
          <span class="view-details__info-text">
            {{ $DwT(creationDate) || '' }}
          </span>
        </div>
      </div>
    </div>

    <div v-else class="view-details__loading">
      <CSpinner color="primary" />
    </div>
  </div>
</template>

<script>
import {
  generateLink,
  generateLinkIcon,
} from '@/views/Templates/Components/Table/generatorLinkByTemplateType'

export default {
  name: 'ViewDetails',
  props: {
    templateId: {
      type: Number,
      default: undefined,
    },
    templateName: {
      type: String,
      default: '',
    },
    templateType: {
      type: String,
      default: '',
    },
    usageList: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '',
    },
    createdBy: {
      type: String,
      default: '',
    },
    lastModified: {
      type: String,
      default: '',
    },
    creationDate: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'setActiveScenario'],
  computed: {
    setIconNameByTemplateType() {
      return generateLinkIcon(this.templateType)
    },
    generateLinkByType() {
      return generateLink({ id: this.templateId, type: this.templateType })
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setActiveScenario(scenarioId, packageId) {
      this.$emit('setActiveScenario', {
        scenarioId,
        packageId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.view-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__icon {
    color: #303c54;

    &--folder,
    &--layers,
    &--description {
      margin-right: 8px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 18px;
    font-weight: 700;
    color: #303c54;
    margin: 0;
  }

  &__subheading {
    font-size: 16px;
    color: #303c54;
    margin: 0 0 10px 0;
  }

  &__template {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__template-link {
    font-size: 14px;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__loading {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    margin-bottom: 30px;
  }

  &__list,
  &__sublist {
    list-style: none;
    margin-bottom: 0;
  }

  &__list {
    padding: 0;
    max-height: 250px;
    overflow-y: auto;
  }

  &__empty {
    opacity: 0.7;
    font-size: 14px;
  }

  &__sublist {
    padding-left: 16px;
  }

  &__list-button {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__list-item {
    background-color: #eeeeef;
    border-radius: 4px;
    padding: 7px 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__list-text {
    display: block;
    font-size: 12px;
    color: #303c54;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
  }

  &__info-text {
    font-size: 12px;
    color: #303c54;
    margin: 0;

    &:not(:last-child) {
      margin-right: 10px;
      flex-shrink: 0;
    }

    &:last-child {
      text-align: right;
      font-weight: 500;
    }
  }
}
</style>
